const projectList = [
    {
        backgroundImage: "https://images.unsplash.com/photo-1578403836063-41de9d397348?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80",
        name: "WhatsApp Audio Speed",
        details: "Acelerador de audios no WhatsApp",
        link: "https://github.com/danilogila"
    },
    {
        backgroundImage: "https://images.unsplash.com/photo-1578403836063-41de9d397348?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80",
        name: "Votação BBB",
        details: "Projeto simulando votação",
        link: "https://github.com/danilogila"
    },
    {
        backgroundImage: "https://images.unsplash.com/photo-1578403836063-41de9d397348?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80",
        name: "Clima Agora",
        details: "Informação sobre clima de cidades",
        link: "https://github.com/danilogila"
    },
    {
        backgroundImage: "https://images.unsplash.com/photo-1578403836063-41de9d397348?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80",
        name: "Receitas Já",
        details: "Busque receitas com o que você tem em casa",
        link: "https://github.com/danilogila"
    },
]


export default projectList
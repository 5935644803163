import React from 'react'
import * as S from './styled'
import Project from '../Project'
import projectList from './content'
import PageDisclaimer from '../PageDisclaimer'
import TextStroke from "../TextStroke"
const style = {
  textStroke: {
    fontFamily: "Bungee",
    // fontSize: "6rem",
    width: "fit-content",
    marginBottom: "3.5rem",
  },
  wrapper: {
    maxWidth: "820px",
    margin: "50px auto",
    display: "block",
    width: "100%",
  }
}

const ProjectList = () => {
    return (
        <div style={{ ...style.wrapper}}>
          <TextStroke style={{ ...style.textStroke}}>
            Projetos
          </TextStroke>
            <S.ProjectList>
                {projectList.map(({ backgroundImage, details, link, name }) => {
                    return (
                        <Project
                            backgroundImage={backgroundImage}
                            details={details}
                            link={link}
                            name={name}
                            key={name}
                        />
                    )
                })}
            </S.ProjectList>
        </div>
    )
}

export default ProjectList

import React from 'react'
import Layout from "../components/Layout/"
import ProjectList from "../components/ProjectList"

import Seo from "../components/seo"

const WorkPage = () => (
	<Layout page="work">
		<Seo title="work" />
		<ProjectList />
	</Layout>
)

export default WorkPage
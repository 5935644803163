import React from 'react'
import * as S from './styled'
import Button from '../Button'

const Project = ({ backgroundImage, name, details, link }) => {
    return (
        <S.ProjectWrapper>
            <S.ProjectContent>
                <img src={backgroundImage} alt={name} />
                <S.ProjectDetails>
                    <h1>{name}</h1>
                    <p>{details}</p>
                </S.ProjectDetails>
            </S.ProjectContent>
            <Button to={link} openNewTab color="rgb(240,162,43)">
                acessar
            </Button>
        </S.ProjectWrapper>
    )
}

export default Project
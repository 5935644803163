import styled from 'styled-components'

export const ProjectWrapper = styled.section`
    
`

export const ProjectContent = styled.section`
    height: 450px;
    filter: grayscale(1);
    border: 2px solid black;
    position: relative;
    overflow: hidden;
    margin-bottom: 15px;

    &:hover {
        filter: grayscale(0);
    }

    &:hover img {
        filter: grayscale(0);
        transform: scale(1.2);
    }

    img {
        width: 100%;
        max-width: 400px;
        height: 100%;
        transition: transform .2s;
    }
`

export const ProjectDetails = styled.section`
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 10px 30px;
    box-sizing: border-box;
    font-family: Roboto Mono;

    h1 {
        font-size: 1rem;
        margin: 0px 0px 15px 0px;
        color: #fff;
        font-weight: bolder;
    }

    p {
        margin: 0px 0px 15px 0px;
        font-size: 0.9rem;
        color: #fff;
    }
`

export const ProjectAccessButton = styled.button`
    padding: 13px 40px;
    border-radius: 0;
    border: 2px solid black;
    background-color: rgb(240,162,43);
    box-shadow: 7px 7px 0px 0px #000000;
    font-size: 1rem;
    cursor: pointer;
    font-weight: 700;
    outline: none;
    margin: 10px 0px;
`



import styled from 'styled-components'

export const ProjectList = styled.section`
    display: grid;
    grid-template-columns: repeat(auto-fill, 350px);
    justify-content: center;
    grid-gap: 20px;
    padding: 0;
    margin-top: 25px;
    list-style: none;

`
